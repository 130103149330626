import { FC, useRef } from 'react';
import { Form, Input, DatePicker, Row, Col, Button } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import CustomSelect from './CustomSelect';
import { DATE_FORMAT } from '../../../../constants/Alert';
import { DISABLED_WHITESPACE } from '../../../../constants/common';
import { AlertTabRowData, RowTadaType } from '../../../../helpers/getAlertData';

const { TextArea } = Input;
interface IProps {
    items: AlertTabRowData[];
    disabled: boolean;
    onBlur: () => void;
}

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    const today = dayjs().startOf('day');
    return !(
        current &&
        current.isSame(today, 'day') &&
        current.isSame(today, 'month') &&
        current.isSame(today, 'year')
    );
};

const AlertsDataOfLegal = ({
    item,
    onBlur,
    disabled,
}: {
    item: AlertTabRowData;
    onBlur: () => void;
    disabled: boolean;
}) => {
    return (
        <Form.List name={item.key} initialValue={Array.isArray(item.value) ? item.value : []}>
            {(fields, { add, remove }) => (
                <>
                    {fields.map(({ key, name, ...restField }) => (
                        <Row key={key} gutter={16} className="alert-data-of-legal">
                            <Form.Item
                                hidden
                                {...restField}
                                name={[name, 'dataType']}
                                initialValue={item.type}
                                rules={[{ required: true }]}>
                                <Input disabled />
                            </Form.Item>
                            <Col span={4}>
                                <Form.Item {...restField} name={[name, 'edrpou']} rules={[{ required: true }]}>
                                    <Input placeholder="ЄДРПОУ" onBlur={onBlur} disabled={disabled} />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item {...restField} name={[name, 'name']} rules={[{ required: true }]}>
                                    <Input placeholder="Найменування" onBlur={onBlur} disabled={disabled} />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item {...restField} name={[name, 'relationType']} rules={[{ required: true }]}>
                                    <Input placeholder="Тип зв’язку" onBlur={onBlur} disabled={disabled} />
                                </Form.Item>
                            </Col>
                            <Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
                                <MinusCircleOutlined onClick={() => remove(name)} hidden={disabled} />
                            </Col>
                        </Row>
                    ))}
                    <Button
                        style={{ marginBottom: 4 }}
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                        disabled={disabled}>
                        Додати
                    </Button>
                </>
            )}
        </Form.List>
    );
};

const EditableTab: FC<IProps> = ({ items, disabled, onBlur }) => {
    const root = useRef<HTMLDivElement>(null);

    return (
        // Враппер поверх Row з відʼємним margin щоб не було горизонального скролу
        <div className="row-margin-reset" ref={root}>
            {items?.map((item) =>
                !item.hide ? (
                    <Row gutter={24} className="alert-info" key={item.key}>
                        <Col span={8}>
                            {item.name}
                            {item.required && <span className="alert-info__required">*</span>}
                        </Col>
                        <Col span={16}>
                            {item.key.includes('alertsDataOfLegal') ? (
                                <AlertsDataOfLegal item={item} onBlur={onBlur} disabled={disabled} />
                            ) : item.editable ? (
                                <Form.Item
                                    className="editable-form-item"
                                    initialValue={item.value}
                                    hasFeedback
                                    name={item.fieldName}
                                    rules={[
                                        { required: item.required },
                                        ...(![RowTadaType.date, RowTadaType.select, RowTadaType.numberInput].includes(
                                            item.type as RowTadaType,
                                        ) && item.required
                                            ? [
                                                  {
                                                      whitespace: true,
                                                      message: DISABLED_WHITESPACE,
                                                  },
                                              ]
                                            : []),
                                    ]}>
                                    {item.type === RowTadaType.date ? (
                                        <DatePicker
                                            className="shortInput"
                                            disabled={disabled}
                                            format={DATE_FORMAT}
                                            onBlur={(e) =>
                                                e.target.value !== dayjs(item.value as Dayjs).format(DATE_FORMAT) &&
                                                onBlur()
                                            }
                                            disabledDate={item.disabledPastPeriod ? disabledDate : undefined}
                                            placeholder={item.placeholder || 'Оберіть дату'}
                                        />
                                    ) : item.type === RowTadaType.select ? (
                                        <CustomSelect
                                            options={item.options}
                                            mode={item.multiply}
                                            disabled={disabled || item.disabled || false}
                                            onBlur={onBlur}
                                            selectMenuContainer={root.current as HTMLDivElement}
                                            placeholder={item.placeholder}
                                        />
                                    ) : item.type === RowTadaType.textArea ? (
                                        <TextArea
                                            placeholder={item.placeholder || 'Введіть значення'}
                                            className="wWide"
                                            disabled={disabled}
                                            rows={item.rows}
                                            onBlur={(e) => e.target.value !== item.value && onBlur()}
                                        />
                                    ) : item.type === RowTadaType.numberInput ? (
                                        <Input
                                            placeholder={item.placeholder || 'Введіть значення'}
                                            type="number"
                                            className="wWide"
                                            disabled={disabled}
                                            style={item.style}
                                            onBlur={(e) => e.target.value !== item.value && onBlur()}
                                        />
                                    ) : (
                                        <Input
                                            placeholder={item.placeholder || 'Введіть значення'}
                                            className="wWide"
                                            disabled={disabled}
                                            style={item.style}
                                            onBlur={(e) => e.target.value !== item.value && onBlur()}
                                        />
                                    )}
                                </Form.Item>
                            ) : (
                                (item.value as string | number | null)
                            )}
                        </Col>
                    </Row>
                ) : null,
            )}
        </div>
    );
};

export default EditableTab;
