import { useMemo } from 'react';
import { Form } from 'antd';
import { Rule } from '../../../models/Alert';
import { RuleModel } from '../../../models/RulesModel';

interface ruleCatalog {
    value: number;
    label: string;
    disabled?: boolean;
}

export const useGetRulesLists = (
    indicators: RuleModel[],
    criteria: RuleModel[],
    autoAddedIndicatorCodes: Rule['id'][],
    autoAddedCriteriaCodes: Rule['id'][],
    alertCriteriaCodes: Rule['id'][],
    approvedCriteriaCodes: Rule['id'][],
    declinedCriteriaCodes: Rule['id'][],
    additionalCriteriaCodes: Rule['id'][],
    alertIndicatorsCodes: Rule['id'][],
) => {
    const form = Form.useFormInstance();
    const isDocumentProvided: number = Form.useWatch('isDocumentsProvided', form);

    const indicatorsCatalog: ruleCatalog[] = useMemo(
        () =>
            indicators.map((rule) => ({
                value: rule.id,
                label: rule.code + '  ' + rule.description,
            })),
        [indicators],
    );

    const criteriaCatalog: ruleCatalog[] = useMemo(
        () =>
            criteria.map((rule) => ({
                value: rule.id,
                label: rule.code + '  ' + rule.description,
                disabled: rule.code === '215' && isDocumentProvided === 0,
            })),
        [criteria, isDocumentProvided],
    );

    const availableToSelectCriteria = useMemo(
        () =>
            alertCriteriaCodes?.filter(
                (code) => !approvedCriteriaCodes?.includes(code) && !declinedCriteriaCodes?.includes(code),
            ),
        [alertCriteriaCodes, approvedCriteriaCodes, declinedCriteriaCodes],
    );

    const listForManualSelectIndicators = useMemo(
        () => indicatorsCatalog.filter((indicator) => !autoAddedIndicatorCodes.includes(indicator.value)),
        [indicatorsCatalog, autoAddedIndicatorCodes],
    );

    const listForManualSelectCriteria = useMemo(
        () => criteriaCatalog.filter((criteria) => !autoAddedCriteriaCodes.includes(criteria.value)),
        [criteriaCatalog, autoAddedCriteriaCodes],
    );

    let availableManual: ruleCatalog[] = [];
    if (availableToSelectCriteria) {
        availableManual = criteriaCatalog.filter((rule) => alertCriteriaCodes?.includes(rule.value));
    }

    let availableManualIndicators = indicatorsCatalog.filter((rule) => alertIndicatorsCodes?.includes(rule.value));
    let availableAdditionalIndicators = indicatorsCatalog.filter((rule) => !alertIndicatorsCodes?.includes(rule.value));

    let availableAdditional: ruleCatalog[] = [];
    if (approvedCriteriaCodes && declinedCriteriaCodes) {
        availableAdditional = criteriaCatalog.filter(
            (rule) =>
                ![...approvedCriteriaCodes, ...declinedCriteriaCodes]?.includes(rule.value) ||
                additionalCriteriaCodes?.includes(rule.value),
        );
    }

    return {
        listForManualSelectIndicators,
        listForManualSelectCriteria,
        availableManual,
        availableAdditional,
        availableManualIndicators,
        availableAdditionalIndicators,
    };
};
