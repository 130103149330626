import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Dropdown, Form, notification, Row, Space, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { QueryOptions } from 'odata-query';
import { CheckOutlined, PrinterOutlined, UndoOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import BankInfo from './BankInfo/BankInfo';
import ClientInfo from './ClientInfo';
import Modal from '../../../../components/common/Modal';
import AmendmentForm, { AmendmentData } from '../../../../components/form/alert-form/Amendment/AmendmentCardForm';
import CloseForm from '../../../../components/form/alert-form/CloseForm';
import DeadlineForm from '../../../../components/form/alert-form/DeadlineForm';
import {
    ALLOWED_CODES_FOR_AGREED,
    ALLOWED_CODES_FOR_PROCESSED,
    STATUS_CODE_AGREED,
    STATUS_CODE_AMENDMENT,
    STATUS_CODE_PROCESSED,
    RULES_TYPE_CRITERION,
    BASE_NOTIFICATION_CONFIG,
} from '../../../../constants';
import { ReportTypes } from '../../../../constants/enums';
import { getErrorFields, getDataForUpdate, reportFetch, hasAllowedController } from '../../../../helpers';
import { baseErrorMsg } from '../../../../helpers/baseErrorMsg';
import { useDebouncedCallback } from '../../../../hooks';
import { ExtendedAlert } from '../../../../models/Alert';
import { RuleModel } from '../../../../models/RulesModel';
import { UserModel } from '../../../../models/UserModel';
import {
    useGetAlertQuery,
    useUpdateAlertMutation,
    useUpdateAlertSilentMutation,
    alertsApi,
    Tags,
} from '../../../../redux/api/alertsApi';
import { useGetSystemDictionaryQuery } from '../../../../redux/api/dictionaryApi';
import { useGetRulesQuery } from '../../../../redux/api/rulesApi';
import { getToken, getUser } from '../../../../redux/features/authSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

export interface Option {
    label: string | number;
    value: number;
}

interface CardProps {
    roleParams?: UserModel['rolesParams'];
    alertId: number;
}

const isSelectedAllRules = (values: any, autoAdded: number[]) => {
    const list = [...autoAdded, ...values.manuallyCriteria];
    const selected = [...values.approvedCriteria, ...values.declinedCriteria];
    return list.every((item) => selected.includes(item));
};

const checkSelectedCriteria = (
    cb: () => void,
    handleErrors: (errors: (string | number)[]) => void,
    values: any,
    alert?: ExtendedAlert,
) => {
    const autoAddedCriteria =
        alert?.rules
            ?.filter((item) => item.addedManually === 0 && item.rule.type.code === RULES_TYPE_CRITERION)
            .map((item) => item.rule.id) ?? [];
    if (isSelectedAllRules(values, autoAddedCriteria)) {
        cb();
    } else {
        handleErrors(['approvedCriteria']);
        baseErrorMsg(
            'Потрібно підтвердити або не підтвердити доцільність встановлення/додавання по усім виявленим критеріям ризику',
        );
    }
};
const rulesQuery: Partial<QueryOptions<RuleModel>> = {
    // @ts-ignore cause of type/code it`s not a key of RuleModel, but key of nested ruleVocabulary
    select: ['id', 'code', 'description', 'type/code', 'score', 'scoreStateEnterprise'],
};

const Card: FC<CardProps> = ({ alertId, roleParams }) => {
    const [form] = useForm();
    const dispatch = useAppDispatch();
    const user = useAppSelector(getUser);
    const token = useAppSelector(getToken);
    const [isEdited, setIsEdited] = useState(false);
    const bankInfoRef = useRef<HTMLDivElement>(null);
    const [approveModal, setApproveModal] = useState(false);
    const [amendmentModal, setAmendmentModal] = useState(false);
    const [deadlineModal, setDeadlineModal] = useState(false);
    const [finishModal, setFinishModal] = useState(false);
    const [errors, setErrors] = useState<(string | number)[]>([]);
    const toggleApproveModal = () => setApproveModal((prev) => !prev);
    const toggleAmendmentModal = () => setAmendmentModal((prev) => !prev);
    const toggleDeadlineModal = () => setDeadlineModal((prev) => !prev);
    const toggleFinishModal = () => setFinishModal((prev) => !prev);
    const { data: alert, isLoading, error } = useGetAlertQuery(alertId);
    const { data: rules = [], error: rulesError } = useGetRulesQuery(rulesQuery);
    const [updateAlert, { isLoading: isUpdatingAlert }] = useUpdateAlertMutation();
    const [updateAlertSilent, result] = useUpdateAlertSilentMutation();
    const [isLoadingReport, setIsLoadingReport] = useState(false);
    const { data: dictSolution, error: errorDictAlertConclusion } =
        useGetSystemDictionaryQuery('dict_alert_conclusions');
    const { data: dictMinRisksMeasures, error: errorDictMinRisksMeasures } =
        useGetSystemDictionaryQuery('dict_min_risks_measures');
    const { data: dictBranches, error: errorDictBranches } = useGetSystemDictionaryQuery('dict_branches');
    const [dataForUpdate, setDataForUpdate] = useState<ExtendedAlert | undefined>(undefined);
    const [spinEnable, setSpinEnable] = useState(false);
    const isController2ndAllowed = !hasAllowedController(alert, user);

    const getAlertConclusion = useCallback(
        async (alertId: number, type: string) => {
            setIsLoadingReport(true);
            await reportFetch(
                ReportTypes.alertConclusion,
                [alertId.toString(), type],
                null,
                null,
                null,
                token,
                user?.id,
            );
            setIsLoadingReport(false);
        },
        [token, user?.id],
    );

    const ids = [alertId];
    const conclusionMenu = useMemo(
        () => ({
            items: [
                {
                    key: 'pdf',
                    label: 'pdf',
                    onClick: () => getAlertConclusion(alertId, 'pdf'),
                },
                {
                    key: 'docx',
                    label: 'docx',
                    onClick: () => getAlertConclusion(alertId, 'docx'),
                },
                {
                    key: 'xlsx',
                    label: 'xlsx',
                    onClick: () => getAlertConclusion(alertId, 'xlsx'),
                },
            ],
        }),
        [alertId, getAlertConclusion],
    );

    const silentUpdate = useDebouncedCallback(() => {
        if (dataForUpdate) {
            setIsEdited(true);
            setSpinEnable(true);
            updateAlertSilent(dataForUpdate)
                .then(() => setSpinEnable(false))
                .catch((e) => baseErrorMsg(e.data.message));
        }
    }, 2000);

    useEffect(() => {
        dataForUpdate && silentUpdate();
        return setDataForUpdate(undefined);
    }, [dataForUpdate, silentUpdate]);

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            const dataForUpdate = getDataForUpdate(values, result?.data || alert, rules);
            setErrors([]);
            checkSelectedCriteria(
                () => {
                    updateAlert({ ...dataForUpdate, status: { code: STATUS_CODE_PROCESSED } })
                        .unwrap()
                        .then(() =>
                            notification.success({
                                ...BASE_NOTIFICATION_CONFIG,
                                message: 'Статус успішно змінено на "Опрацьовано"',
                            }),
                        )
                        .then(() => setIsEdited(true))
                        .catch((e) => baseErrorMsg(e.data.message));
                },
                setErrors,
                values,
                alert,
            );
        } catch (e) {
            handleErrors(e);
        }
    };

    const handleErrors = (e: any) => {
        baseErrorMsg('Заповніть всі обовʼязкові поля');
        setErrors(getErrorFields(e.errorFields));
    };

    const handleApprove = async () => {
        try {
            const values = await form.validateFields();
            const dataForUpdate = getDataForUpdate(values, result?.data || alert, rules);
            setErrors([]);
            checkSelectedCriteria(
                () => {
                    updateAlert({
                        ...dataForUpdate,
                        status: { code: STATUS_CODE_AGREED },
                        controller: { id: user?.id },
                    })
                        .unwrap()
                        .then(() => {
                            notification.success({
                                ...BASE_NOTIFICATION_CONFIG,
                                message: 'Статус успішно змінено на "Погоджено"',
                            });
                            toggleApproveModal();
                        })
                        .then(() => setIsEdited(true))
                        .catch((e) => baseErrorMsg(e.data.message));
                },
                setErrors,
                values,
                alert,
            );
        } catch (e) {
            handleErrors(e);
        }
    };

    const handleAmendment = async (data: AmendmentData) => {
        const values = await form.getFieldsValue();
        const dataForUpdate = getDataForUpdate(values, result?.data || alert, rules);
        setErrors([]);
        updateAlert({
            ...dataForUpdate,
            ...data,
            status: { code: STATUS_CODE_AMENDMENT },
            controller: { id: user?.id },
        })
            .unwrap()
            .then(() => {
                notification.success({
                    ...BASE_NOTIFICATION_CONFIG,
                    message: 'Статус успішно змінено на "Доопрацювання"',
                });
                toggleAmendmentModal();
            })
            .then(() => setIsEdited(true))
            .catch((e) => baseErrorMsg(e.data.message));
    };

    useEffect(() => {
        let errorMsg: any = null;

        if (error) errorMsg = error;
        if (rulesError) errorMsg = rulesError;
        if (errorDictAlertConclusion) errorMsg = errorDictAlertConclusion;
        if (errorDictMinRisksMeasures) errorMsg = errorDictMinRisksMeasures;
        if (errorDictBranches) errorMsg = errorDictBranches;

        if (errorMsg) baseErrorMsg(errorMsg?.data?.message);
    }, [error, errorDictAlertConclusion, errorDictBranches, errorDictMinRisksMeasures, rulesError]);

    useEffect(() => {
        return () => {
            if (isEdited) {
                dispatch(alertsApi.util.invalidateTags([Tags.alerts]));
            }
        };
    }, [dispatch, isEdited]);

    return (
        <Spin wrapperClassName="alert-card__spinner" spinning={isLoading || isUpdatingAlert || isLoadingReport}>
            <Modal onCancel={toggleApproveModal} title={`Підтвердження`} open={approveModal}>
                <div>Ви дійсно бажаєте погодити?</div>
                <Row justify="end" gutter={16}>
                    <Col>
                        <Button onClick={toggleApproveModal} loading={isUpdatingAlert}>
                            Ні
                        </Button>
                    </Col>
                    <Col>
                        <Button type="primary" onClick={handleApprove} loading={isUpdatingAlert}>
                            Так
                        </Button>
                    </Col>
                </Row>
            </Modal>
            <Modal onCancel={toggleAmendmentModal} title={`Повернення на доопрацювання`} open={amendmentModal}>
                <AmendmentForm onSuccess={handleAmendment} analyst={alert?.analyst} />
            </Modal>
            <Modal
                width="328px"
                onCancel={toggleDeadlineModal}
                title={`Зміна терміну обробки заявки`}
                open={deadlineModal}>
                <DeadlineForm ids={ids} onSuccess={toggleDeadlineModal} />
            </Modal>
            <Modal onCancel={toggleFinishModal} title={`Підтвердження`} open={finishModal}>
                <CloseForm ids={ids} onSuccess={toggleFinishModal} onCancel={toggleFinishModal} />
            </Modal>
            <Form
                form={form}
                validateMessages={{
                    required: 'Обов’язкове поле!',
                }}>
                <div className="card-content">
                    <Row gutter={[8, 8]} justify="space-between">
                        <Col>
                            <Row gutter={[8, 8]}>
                                {roleParams?.IS_ANALYST && (
                                    <Col>
                                        <Button
                                            disabled={!ALLOWED_CODES_FOR_PROCESSED.includes(alert?.status?.code || '')}
                                            onClick={handleSubmit}
                                            icon={<CheckOutlined />}>
                                            Опрацювати
                                        </Button>
                                    </Col>
                                )}
                                {roleParams?.IS_CONTROLLER && (
                                    <>
                                        <Col>
                                            <Button
                                                disabled={
                                                    !(alert?.status?.code === STATUS_CODE_PROCESSED) ||
                                                    isController2ndAllowed
                                                }
                                                onClick={toggleAmendmentModal}
                                                icon={<UndoOutlined />}>
                                                Повернути на доопрацювання
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                disabled={
                                                    !ALLOWED_CODES_FOR_AGREED.includes(alert?.status?.code || '') ||
                                                    isController2ndAllowed
                                                }
                                                onClick={toggleApproveModal}
                                                icon={<CheckOutlined />}>
                                                Погодити
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                disabled={
                                                    !(alert?.status?.code === STATUS_CODE_AGREED) ||
                                                    isController2ndAllowed
                                                }
                                                onClick={toggleFinishModal}
                                                icon={<CloseCircleOutlined />}>
                                                Закрити
                                            </Button>
                                        </Col>
                                    </>
                                )}
                            </Row>
                        </Col>
                        <Col>
                            <Space size="middle">
                                <Spin spinning={spinEnable} indicator={<LoadingOutlined />} />
                                <Dropdown menu={conclusionMenu}>
                                    <Button icon={<PrinterOutlined />}>Друк документу "Висновок"</Button>
                                </Dropdown>
                            </Space>
                        </Col>
                    </Row>
                    <div className="card-content__tab">
                        {alert && (
                            <ClientInfo
                                onSave={() => setDataForUpdate(getDataForUpdate(form.getFieldsValue(), alert, rules))}
                                model={alert}
                                errorKeyList={errors}
                                rules={rules}
                                solution={dictSolution}
                                minRisksMeasures={dictMinRisksMeasures}
                                branches={dictBranches}
                            />
                        )}
                    </div>
                    <div ref={bankInfoRef} className="card-content__tab">
                        {alert && <BankInfo model={alert} />}
                    </div>
                </div>
            </Form>
        </Spin>
    );
};

export default Card;
