import dayjs from 'dayjs';
import { DATE_FORMAT_FOR_QUERY } from '../constants/Alert';
import { RULES_TYPE_CRITERION, RULES_TYPE_INDICATOR } from '../constants/Rules';
import { AlertFields } from '../constants/enums';
import { ExtendedAlert, Rule } from '../models/Alert';
import { RuleModel } from '../models/RulesModel';

const dateFields = [
    AlertFields.firstCurrentAccountDate,
    AlertFields.sendingRequestToCustomerDate,
    AlertFields.deadlineProvideDocuments,
    AlertFields.providingDocumentsDate,
    AlertFields.conclusionDate,
    AlertFields.requestReceivedDate,
    AlertFields.dateOfDeclineProvidingDocuments,
];

const extensions = [
    AlertFields.manuallyCriteria,
    AlertFields.approvedCriteria,
    AlertFields.declinedCriteria,
    AlertFields.manuallyIndicator,
    AlertFields.totalRiskSumScore,
    AlertFields.additionalCriteria,
];

const booleanFields = [
    AlertFields.isRequestReceived,
    AlertFields.isDocumentsProvided,
    AlertFields.isIndependentActivity,
    AlertFields.isClientTrustor,
    AlertFields.isClientTrustedPerson,
    AlertFields.isSendingRequestToCustomer,
    AlertFields.isCustomerDeclinedProvidingDocuments,
    AlertFields.isAddressMassRegistration,
    AlertFields.isClientBeneficiary,
    AlertFields.isManyVED,
    AlertFields.isHeadHeadInOtherCompanies,
    AlertFields.isHeadCFOinOtherCompanies,
    AlertFields.isCFOHeadInOtherCompanies,
    AlertFields.isCFOCFOInOtherCompanies,
];

const dataOfLegalFields = [
    AlertFields.alertsDataOfLegal,
    AlertFields.alertsDataOfLegalCfo,
    AlertFields.alertsDataOfLegalClient,
];

export const mapAlertDataToDto = (values: any): any => {
    const result: any = {};
    const keys: AlertFields[] = Object.keys(values) as AlertFields[];
    keys?.forEach((key) => {
        if (values[key] !== null && values[key] !== undefined) {
            if (dateFields.includes(key) && values[key] !== '') {
                result[key] = dayjs(values[key]).format(DATE_FORMAT_FOR_QUERY);
            } else if (key === 'customer') {
                result[key] = mapAlertDataToDto(values[key]);
            } else if (extensions.includes(key)) {
            } else if (booleanFields.includes(key)) {
                result[key] = values[key];
            } else if (dataOfLegalFields.includes(key)) {
                result[AlertFields.alertsDataOfLegal] = [
                    ...(result[AlertFields.alertsDataOfLegal] || []),
                    ...values[key],
                ];
            } else {
                result[key] = values[key].toString().trim();
            }
        }
    });
    return result;
};

const mapCodesToRules = (
    codes?: number[],
    alertRules?: Rule[],
    rules?: RuleModel[],
    alertId?: number,
    approvedCodes?: number[],
    additionalCriteria?: number[],
) =>
    codes?.map((id: number) => {
        const alertRule = alertRules?.find((item) => item.rule?.id === id);
        const rule = rules?.find((item) => item.id === id);
        let result: any = alertRule ? { ...alertRule } : rule ? { ...rule } : {};
        result.isFromAbs = alertRule ? alertRule.isFromAbs : 0;
        result.addedManually = 1;
        result.approved = approvedCodes?.includes(id) ? 1 : 0;
        result.additional = additionalCriteria?.includes(id) ? 1 : 0;
        result.alert = { id: alertId };
        result.rule = alertRule?.rule || rule;
        result.id = alertRule?.id;
        result.ruleScore = alertRule?.ruleScore || rule?.score;

        return result;
    }) || [];

const updateRules = (values: any, model?: ExtendedAlert, rules?: RuleModel[]) => {
    const criteria = model?.rules?.filter((criteria) => criteria.rule.type.code === RULES_TYPE_CRITERION) ?? [];
    const indicators = model?.rules?.filter((criteria) => criteria.rule.type.code === RULES_TYPE_INDICATOR) ?? [];

    const autoAddedIndicators = indicators
        .filter((indicator) => indicator.addedManually === 0)
        .map((indicator) => ({
            ...indicator,
            approved: values.declinedIndicators?.includes(indicator.rule.id) ? 0 : 1,
        }));
    const manuallyAddedIndicators = mapCodesToRules(
        values.manuallyIndicator,
        indicators,
        rules,
        model?.id,
        values.approvedIndicators,
        values.additionalAddedIndicators,
    );

    const autoAddedCriteria = criteria
        .filter((criteria) => criteria.addedManually === 0)
        .map((criteria) => ({
            ...criteria,
            approved: values.declinedCriteria?.includes(criteria.rule.id) ? 0 : 1,
        }));

    const manuallyAddedCriteria = mapCodesToRules(
        values.manuallyCriteria,
        criteria,
        rules,
        model?.id,
        values.approvedCriteria,
    );

    const additionalAddedCriteria = mapCodesToRules(
        values.additionalCriteria,
        criteria,
        rules,
        model?.id,
        undefined,
        values.additionalCriteria,
    );

    const additionalAddedIndicators = mapCodesToRules(
        values.additionalIndicators,
        indicators,
        rules,
        model?.id,
        undefined,
        values.additionalIndicators,
    );

    return [
        ...autoAddedCriteria,
        ...manuallyAddedCriteria,
        ...autoAddedIndicators,
        ...manuallyAddedIndicators,
        ...additionalAddedCriteria,
        ...additionalAddedIndicators,
    ];
};

export const getDataForUpdate = (values: any, model?: ExtendedAlert, rules?: RuleModel[]) => {
    const data = mapAlertDataToDto(values);
    data.id = model?.id;
    if (data.customer) {
        data.customer.customerId = model?.customer?.customerId;
    }
    data.conclusion = values.conclusion.map((item: any) => ({ id: item }));
    data.minRisksMeasures = values.minRisksMeasures.map((item: any) => ({ id: item }));
    data.branches = values.branches?.map((item: any) => ({ id: item }));
    data.rules = updateRules(values, model, rules);
    return data;
};
