import { ReactNode } from 'react';
import { FormInstance, Tooltip } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { getAbbr } from './getAbbr';
import { getDateView } from './getDateView';
import Skeleton from '../components/common/Skeleton';
import UserModal from '../components/modal/UserModal';
import {
    ALERT_PARAMETER,
    CUSTOMER_TYPES_INDIVIDUAL,
    CUSTOMER_TYPES_INDIVIDUAL_ENTREPRENEUR,
    DATE_FORMAT,
    INDIVIDUAL_TYPES,
    LEGAL_TYPES,
    RULES_TYPE_CRITERION,
    RULES_TYPE_INDICATOR,
} from '../constants';
import { AlertAdditionalInfo, AlertDataOfLegal, BaseAlert, CustomerAddress, ExtendedAlert } from '../models/Alert';
import { RecordModel } from '../models/DictionaryModel';
import { UserModel } from '../models/UserModel';
import { Option } from '../pages/alerts/components/card/Card';

const approveOption = [
    { value: 1, label: 'Так' },
    { value: 0, label: 'Ні' },
];

const isDocumentsProvidedOptions = [
    { value: 1, label: 'Так' },
    { value: 0, label: 'Ні' },
    { value: 2, label: 'Частково' },
    { value: 3, label: 'Не запитувались' },
];

const numberOfIncomingLetterStyle = { width: '280px' };

const getLegalAddress = (addresses?: CustomerAddress[]) => {
    const data = addresses?.find((addr) => addr.addressType === 'Юридична');
    const result = [];
    if (data?.country) result.push(data?.country);
    if (data?.postCode) result.push(data?.postCode);
    if (data?.region) result.push(data?.region);
    if (data?.area) result.push(data?.area);
    if (data?.locality) result.push(`м. ${data?.locality}`);
    if (data?.addressText) result.push(`${data?.addressText}`);

    return result.length ? result.join(', ') : '';
};

const getCustomerAddress = (addresses?: CustomerAddress[]) => {
    const data = addresses?.find((addr) => addr.addressType === 'Фактична');
    const result = [];
    if (data?.country) result.push(data?.country);
    if (data?.postCode) result.push(data?.postCode);
    if (data?.region) result.push(data?.region);
    if (data?.area) result.push(data?.area);
    if (data?.locality) result.push(`м. ${data?.locality}`);
    if (data?.street) result.push(`${data?.streetType} ${data?.street}`);
    if (data?.house) result.push(`${data?.houseType ? data?.houseType + ' ' : ''}${data?.house}`);
    if (data?.room) result.push(`${data.roomType ? data.roomType + ' ' : ''}${data?.room}`);
    return result.length ? result.join(', ') : '';
};

const isExist = (value?: string | number | boolean | null) => (!!value ? 'Так' : 'Ні');
const isLegalType = (type?: string) => LEGAL_TYPES.includes(type || '');
const isIndividualType = (type?: string) => INDIVIDUAL_TYPES.includes(type || '');

export enum RowTadaType {
    date = 'date',
    select = 'select',
    textArea = 'textArea',
    numberInput = 'numberInput',
    clientData = 'client-data',
    cfoData = 'cfo-data',
}
interface BaseAlertTabRowData {
    key: string;
    name: string;
    editable?: boolean;
    hide?: boolean;
    type?: RowTadaType;
    required?: boolean;
    placeholder?: string;
    disabled?: boolean;
    rows?: number; // for textArea
    style?: { [index: string]: string };
    multiply?: boolean; // for select
    options?: any[]; // for select
    disabledPastPeriod?: boolean; // for datePicker
}

interface EditableAlertTabRowData extends BaseAlertTabRowData {
    editable: true;
    value: string | number | boolean | null | Dayjs | number[] | string[] | ReactNode | AlertDataOfLegal[];
    fieldName: string | string[];
}

interface NonEditableAlertTabRowData extends BaseAlertTabRowData {
    editable?: false;
    value?: string | number | boolean | null | Dayjs | number[] | string[] | ReactNode | AlertDataOfLegal[];
}

export type AlertTabRowData = EditableAlertTabRowData | NonEditableAlertTabRowData;

const getAdditionalInfo = (key: string, additionalInfo?: AlertAdditionalInfo, isAdditionalInfoLoading?: boolean) => {
    if (!additionalInfo) {
        return <Skeleton loading={isAdditionalInfoLoading} />;
    }

    switch (key) {
        case ALERT_PARAMETER.analysisPeriod:
            return `${getDateView(additionalInfo.analysisFromDate, true)} - ${getDateView(
                additionalInfo.analysisToDate,
                true,
            )}`;
        case 'firstCurrentAccountDate':
            return getDateView(additionalInfo.firstCurrentAccountDate, true);
        default:
            return '';
    }
};

export const getGeneralData = (
    alert: ExtendedAlert,
    additionalInfo?: AlertAdditionalInfo,
    isAdditionalInfoLoading?: boolean,
): AlertTabRowData[] => [
    {
        key: ALERT_PARAMETER.status,
        name: 'Статус',
        value: alert.status?.description,
        editable: false,
    },
    { key: ALERT_PARAMETER.id, name: 'Номер алерту', value: alert.id, editable: false },
    {
        key: ALERT_PARAMETER.createdDate,
        name: 'Дата генерації алерту',
        value: alert.createdDate && dayjs(alert.createdDate).format(DATE_FORMAT),
        editable: false,
    },
    {
        key: ALERT_PARAMETER.urgency,
        name: 'Терміновість опрацювання',
        value: alert.urgency?.description,
        editable: false,
    },
    {
        key: ALERT_PARAMETER.executionDate,
        name: 'Планова дата виконання',
        value: alert.executionDate && dayjs(alert.executionDate).format(DATE_FORMAT),
        editable: false,
    },
    {
        key: ALERT_PARAMETER.analysisPeriod,
        name: 'Аналізований період',
        value: getAdditionalInfo(ALERT_PARAMETER.analysisPeriod, additionalInfo, isAdditionalInfoLoading),
        editable: false,
    },
    {
        key: ALERT_PARAMETER.analyst,
        name: 'Аналітик',
        value: <UserModal user={alert.analyst} />,
        editable: false,
    },
    {
        key: ALERT_PARAMETER.analystEmail.join('/'),
        name: 'Корпоративна електронна скринька',
        value: alert.analyst?.email,
        editable: false,
    },
    {
        key: ALERT_PARAMETER.controller,
        name: 'Контролер',
        value: <UserModal user={alert.controller} />,
        editable: false,
    },
    {
        key: ALERT_PARAMETER.controllerEmail.join('/'),
        name: 'Корпоративна електронна скринька',
        value: alert.controller?.email,
        editable: false,
    },
];

export const getRequisitesData = (
    alert: ExtendedAlert,
    additionalInfo: AlertAdditionalInfo | undefined,
    isAdditionalInfoLoading: boolean,
    form: FormInstance<any>,
): AlertTabRowData[] => {
    const isClientIndividual = alert.customer?.customerTypeName === CUSTOMER_TYPES_INDIVIDUAL;
    const isClientIndividualEntrepreneur = alert.customer?.customerTypeName === CUSTOMER_TYPES_INDIVIDUAL_ENTREPRENEUR;
    const isClientLegal = isLegalType(alert.customer?.customerTypeName);
    const isClientPhysical = isIndividualType(alert.customer?.customerTypeName);
    return [
        {
            key: 'customerTypeName', // ФО/ФОП/ЮО
            name: 'Тип клієнта',
            value: getAbbr(alert.customer?.customerTypeName),
            editable: false,
        },
        {
            key: 'hasAnotherType', // ФО/ФОП
            name: `Чи обслуговується також як ФО${isClientIndividual ? 'П' : ''}?`,
            value: isExist(alert.customer?.hasAnotherType),
            editable: false,
            hide: isClientLegal,
        },
        {
            key: 'ved', // ФО
            name: '«КВЕД (код та назва)»',
            value: alert.customer?.ved + (alert.customer?.vedDescription ? ` - ${alert.customer?.vedDescription}` : ''),
            editable: false,
            hide: !isClientIndividual,
        },
        {
            key: 'isIndependentActivity', // ФО
            name: 'Чи здійснює клієнт незалежну професійну діяльність?',
            value: isExist(alert.isIndependentActivity),
            editable: false,
            hide: !isClientIndividual,
        },
        {
            key: 'independentActivityType', // ФО
            name: 'Вид діяльності',
            value: alert.independentActivityType,
            editable: true,
            fieldName: ['independentActivityType'],
            hide: !isClientIndividual || !form?.getFieldValue('isIndependentActivity'),
            required: form?.getFieldValue('isIndependentActivity'),
        },
        {
            key: 'isAddressMassRegistration', // ФОП/ЮО
            name: 'За адресою клієнта зареєстровано велику кількість (більше 5) інших компаній/ФОП?',
            value: alert.isAddressMassRegistration,
            editable: true,
            fieldName: ['isAddressMassRegistration'],
            type: RowTadaType.select,
            options: approveOption,
            hide: isClientIndividual,
            required: true,
        },
        {
            key: 'massRegistrationAddressCount', // ФОП/ЮО
            name: 'Кількість інших компаній/ФОП',
            value: alert.massRegistrationAddressCount,
            editable: true,
            fieldName: ['massRegistrationAddressCount'],
            type: RowTadaType.numberInput,
            hide: isClientIndividual || !form?.getFieldValue('isAddressMassRegistration'),
            required: form?.getFieldValue('isAddressMassRegistration'),
        },
        {
            key: 'isClientTrustor', // ФО/ФОП
            name: `Чи є клієнт${isClientIndividualEntrepreneur ? ',як ФО, ' : ' '}довірителем іншого клієнта Банку?`,
            value: alert.isClientTrustor,
            editable: true,
            hide: isClientLegal,
            fieldName: ['isClientTrustor'],
            type: RowTadaType.select,
            options: approveOption,
            required: true,
        },
        {
            key: 'trustorData', // ФО/ФОП
            name: 'Дані особи (ПІБ, дата народження, РНОКПП (за наявності))',
            value: alert.trustorData,
            editable: true,
            fieldName: ['trustorData'],
            hide: isClientLegal || !form?.getFieldValue('isClientTrustor'),
            required: form?.getFieldValue('isClientTrustor'),
        },
        {
            key: 'isClientTrustedPerson', // ФО/ФОП
            name: `Чи є клієнт${
                isClientIndividualEntrepreneur ? ',як ФО, ' : ' '
            }довіреною особою іншого клієнта Банку?`,
            value: alert.isClientTrustedPerson,
            editable: true,
            hide: isClientLegal,
            fieldName: ['isClientTrustedPerson'],
            type: RowTadaType.select,
            options: approveOption,
            required: true,
        },
        {
            key: 'trustedPersonData', // ФО
            name: 'Дані особи (ПІБ, дата народження, РНОКПП (за наявності))',
            value: alert.trustedPersonData,
            editable: true,
            fieldName: ['trustedPersonData'],
            hide: isClientLegal || !form?.getFieldValue('isClientTrustedPerson'),
            required: form?.getFieldValue('isClientTrustedPerson'),
        },
        {
            key: 'isClientBeneficiary', // ФОП
            name: `Чи є клієнт, як ФО, КБВ та/або керівником інших компаній?`,
            value: alert.isClientBeneficiary,
            editable: true,
            hide: !isClientIndividualEntrepreneur,
            fieldName: ['isClientBeneficiary'],
            type: RowTadaType.select,
            options: approveOption,
            required: true,
        },
        {
            key: 'clientBeneficiaryCount', // ФОП
            name: 'Кількість таких компаній',
            value: alert.clientBeneficiaryCount,
            editable: true,
            fieldName: ['clientBeneficiaryCount'],
            type: RowTadaType.numberInput,
            hide: !isClientIndividualEntrepreneur || !form?.getFieldValue('isClientBeneficiary'),
            required: form?.getFieldValue('isClientBeneficiary'),
        },
        {
            key: 'alertsDataOfLegalClientData', // ФО/ФОП/ЮО
            name: "Дані юридичних осіб (найменування та ЄДРПОУ (за наявності)), які є клієнтами Банку та в яких клієнт як ФО є керівником/КБВ, та тип зв'язку (за наявності)",
            value: alert.alertsDataOfLegal?.filter((item) => item.dataType === RowTadaType.clientData),
            type: RowTadaType.clientData,
        },
        {
            key: 'businessSegment', // ФО/ФОП/ЮО
            name: 'Сегмент бізнесу',
            value: alert.customer?.businessSegment,
            editable: false,
        },
        {
            key: 'dpaRegistrationDate', // ФОП/ЮО
            name: 'Дата державної реєстрації',
            value: getDateView(alert.customer?.dpaRegistrationDate, true, true),
            editable: false,
            hide: isClientIndividual,
        },
        {
            key: 'birthday', // ФО/ФОП
            name: 'Дата народження',
            value: getDateView(alert.customer?.birthday, true, true),
            editable: false,
            hide: isClientLegal,
        },
        {
            key: 'Citizenship', // ФО/ФОП
            name: 'Громадянство',
            value:
                ((alert.customer?.citizenshipCode && alert.customer?.citizenshipCode + ' - ') || '') +
                (alert.customer?.citizenshipName || ''),
            editable: false,
            hide: isClientLegal,
        },
        {
            key: 'Residency', // ФО/ФОП/ЮО
            name: 'Резидентність',
            value:
                ((alert.customer?.countryCode && alert.customer?.countryCode + ' - ') || '') +
                (alert.customer?.countryName || ''),
            editable: false,
        },
        {
            key: 'addresses', // ФО/ФОП
            name: 'Місце проживання або перебування',
            value: getCustomerAddress(alert.customer.addresses),
            editable: false,
            hide: isClientLegal,
        },
        {
            key: 'temporaryAddress', // ФО
            name: 'Місце проживання або тимчасового перебування в Україні',
            value: getLegalAddress(alert.customer.addresses),
            editable: false,
            hide: !isClientIndividual || (!alert.customer?.countryCode && !alert.customer?.countryName),
        },
        {
            key: 'registrationAddress', // ЮО
            name: 'Місцезнаходження',
            value: getLegalAddress(alert.customer.addresses),
            editable: false,
            hide: isClientPhysical,
        },
        {
            key: 'anotherAddressInfo', // ФО/ФОП/ЮО
            name: 'Інші відомості щодо адреси (за наявності)',
            value: alert.anotherAddressInfo,
            editable: true,
            fieldName: ['anotherAddressInfo'],
        },
        {
            key: 'massRegistrationAddress', // ФО/ФОП/ЮО
            name: 'Чи належить адреса клієнта до адрес масової реєстрації?',
            value: alert.massRegistrationAddress,
            editable: true,
            fieldName: ['massRegistrationAddress'],
            type: RowTadaType.select,
            options: approveOption,
        },
        {
            key: 'numberOfRegistredSGD', // ФО/ФОП/ЮО
            name: 'Якщо "Так", то кількість зареєстрованих СГД складає:',
            value: alert.numberOfRegistredSGD,
            editable: false,
        },
        {
            key: 'socialStatus', // ФО
            name: 'Соціальний статус',
            value: alert.socialStatus,
            editable: true,
            fieldName: ['socialStatus'],
            hide: !isClientIndividual,
        },
        {
            key: 'typeOfEmployment', // ФО
            name: 'Статус зайнятості особи',
            value: alert.typeOfEmployment,
            editable: true,
            fieldName: ['typeOfEmployment'],
            hide: !isClientIndividual,
        },
        {
            key: 'work', // ФО
            name: 'Місце роботи',
            value: alert.work,
            editable: true,
            fieldName: ['work'],
            hide: !isClientIndividual,
        },
        {
            key: 'Посада', // ФО
            name: 'Посада',
            value: '-',
            //   editable: true,
            fieldName: 'Посада',
            hide: !isClientIndividual,
        },
        {
            key: 'informationAboutHead', // ЮО
            name: 'Інформація про керівника (ПІБ, дата народження, РНОКПП (за наявності))',
            value: alert.informationAboutHead,
            hide: isClientPhysical,
        },
        {
            key: 'isHeadHeadInOtherCompanies', // ЮО
            name: 'Керівник клієнта є також керівником інших компаній?',
            value: alert.isHeadHeadInOtherCompanies,
            editable: true,
            fieldName: ['isHeadHeadInOtherCompanies'],
            type: RowTadaType.select,
            options: approveOption,
            hide: isClientPhysical,
            required: true,
        },
        {
            key: 'headHeadInOtherCompaniesCount', // ЮО
            name: 'Кількість таких компаній',
            value: alert.headHeadInOtherCompaniesCount,
            editable: true,
            fieldName: ['headHeadInOtherCompaniesCount'],
            type: RowTadaType.numberInput,
            hide: isClientPhysical || !form?.getFieldValue('isHeadHeadInOtherCompanies'),
            required: form?.getFieldValue('isHeadHeadInOtherCompanies'),
        },
        {
            key: 'isHeadCFOinOtherCompanies', // ЮО
            name: 'Керівник клієнта є КБВ інших компаній?',
            value: alert.isHeadCFOinOtherCompanies,
            editable: true,
            fieldName: ['isHeadCFOinOtherCompanies'],
            type: RowTadaType.select,
            options: approveOption,
            hide: isClientPhysical,
            required: true,
        },
        {
            key: 'headCFOinOtherCompaniesCount', // ЮО
            name: 'Кількість таких компаній',
            value: alert.headCFOinOtherCompaniesCount,
            editable: true,
            fieldName: ['headCFOinOtherCompaniesCount'],
            type: RowTadaType.numberInput,
            hide: isClientPhysical || !form?.getFieldValue('isHeadCFOinOtherCompanies'),
            required: form?.getFieldValue('isHeadCFOinOtherCompanies'),
        },
        {
            key: 'informationAboutCFO', // ЮО
            name: 'Інформація про КБВ (ПІБ, дата народження, РНОКПП (за наявності))',
            value: alert.informationAboutCFO,
            hide: isClientPhysical,
        },
        {
            key: 'CFOCitizenship', // ЮО
            name: 'Країна громадянства постійного місця проживання',
            value: '-',
            hide: isClientPhysical,
        },
        {
            key: 'CFOStake', // ЮО
            name: 'Характер та міра (рівень, ступінь, частка) бенефіціарного володіння (вигоди, інтересу, впливу)',
            value: alert.CFOStake,
            hide: isClientPhysical,
        },
        {
            key: 'isCFOHeadInOtherCompanies', // ЮО
            name: 'КБВ клієнта є керівником інших компаній?',
            value: alert.isCFOHeadInOtherCompanies,
            editable: true,
            fieldName: ['isCFOHeadInOtherCompanies'],
            type: RowTadaType.select,
            options: approveOption,
            hide: isClientPhysical,
            required: true,
        },
        {
            key: 'CFOHeadInOtherCompaniesCount', // ЮО
            name: 'Кількість таких компаній',
            value: alert.CFOHeadInOtherCompaniesCount,
            editable: true,
            fieldName: ['CFOHeadInOtherCompaniesCount'],
            type: RowTadaType.numberInput,
            hide: isClientPhysical || !form?.getFieldValue('isCFOHeadInOtherCompanies'),
            required: form?.getFieldValue('isCFOHeadInOtherCompanies'),
        },

        {
            key: 'isCFOCFOInOtherCompanies', // ЮО
            name: 'КБВ клієнта є керівником інших компаній?',
            value: alert.isCFOCFOInOtherCompanies,
            editable: true,
            fieldName: ['isCFOCFOInOtherCompanies'],
            type: RowTadaType.select,
            options: approveOption,
            hide: isClientPhysical,
            required: true,
        },
        {
            key: 'CFOCFOInOtherCompaniesCount', // ЮО
            name: 'Кількість таких компаній',
            value: alert.CFOCFOInOtherCompaniesCount,
            editable: true,
            type: RowTadaType.numberInput,
            fieldName: ['CFOCFOInOtherCompaniesCount'],
            hide: isClientPhysical || !form?.getFieldValue('isCFOCFOInOtherCompanies'),
            required: form?.getFieldValue('isCFOCFOInOtherCompanies'),
        },
        {
            key: 'alertsDataOfLegalCfoData', // ЮО
            name: "Дані юридичних осіб (найменування та ЄДРПОУ (за наявності)), які є клієнтами Банку та в яких КБВ клієнта є керівником/КБВ, та тип зв'язку (за наявності)",
            value: alert.alertsDataOfLegal?.filter((item) => item.dataType === RowTadaType.cfoData),
            type: RowTadaType.cfoData,
            hide: isClientPhysical,
        },
        {
            key: 'isCustomerPEP', // ФО/ФОП/ЮО
            name: 'Належність до категорії РЕР',
            value: isExist(alert.customer?.isCustomerPEP),
            editable: false,
        },
        {
            key: 'customerPEPId', // ФО/ФОП/ЮО
            name: 'Категорія посади РЕР (ФО/ФОП) / Категорія PEP ЮО',
            value: isClientPhysical ? alert.customer?.physicalCustomerPEPId : alert.customer?.juridicalCustomerPEPId,
            editable: false,
        },
        {
            key: 'ved', // ФОП/ЮО
            name: 'Основний КВЕД',
            value: alert.customer?.ved + ' - ' + alert.customer?.vedDescription,
            editable: false,
            hide: isClientIndividual,
        },
        {
            key: 'isManyVED', // ФОП/ЮО
            name: 'Наявність великої кількості зареєстрованих видів діяльності (10 і більше КВЕД)',
            value: alert.isManyVED,
            editable: true,
            fieldName: ['isManyVED'],
            type: RowTadaType.select,
            options: approveOption,
            hide: isClientIndividual,
            required: true,
        },
        {
            key: 'kvedQuantity', // ФОП/ЮО
            name: 'Кількість КВЕД',
            value: alert.kvedQuantity,
            editable: true,
            fieldName: ['kvedQuantity'],
            type: RowTadaType.numberInput,
            hide: isClientIndividual || !form?.getFieldValue('isManyVED'),
            required: form?.getFieldValue('isManyVED'),
        },
        {
            key: 'licensePermitsInfo', // ФОП/ЮО
            name: 'Інформація щодо наявності ліцензій/дозволів',
            value: alert.licensePermitsInfo,
            editable: false,
            hide: isClientIndividual,
        },
        {
            key: 'authorizedCapital', // ФОП/ЮО
            name: 'Розмір статутного капіталу у грн.',
            value: alert.customer?.authorizedCapital,
            editable: false,
            hide: isClientPhysical,
        },
        {
            key: 'numberOfEmployees', // ФОП/ЮО
            name: 'Кількість працівників',
            value: alert.numberOfEmployees,
            editable: true,
            fieldName: ['numberOfEmployees'],
            type: RowTadaType.numberInput,
            hide: isClientIndividual,
        },
        {
            key: 'businessRelationshipDate', // ФО/ФОП/ЮО
            name: 'Дата встановлення ділових відносин',
            value: getDateView(alert.customer?.businessRelationshipDate, true, true),
            editable: false,
        },
        {
            key: 'firstCurrentAccountDate', // ФО/ФОП/ЮО
            name: 'Дата відкриття першого поточного рахунку',
            value: getAdditionalInfo('firstCurrentAccountDate', additionalInfo, isAdditionalInfoLoading),
            editable: false,
        },
    ];
};

export const getProfileData = (alert: ExtendedAlert): AlertTabRowData[] => [
    {
        key: 'riskLevelOnCreate', // ФО/ФОП/ЮО
        name: 'Первинний рівень ризику ',
        value: alert.riskLevelOnCreate,
        editable: false,
    },
    {
        key: 'riskLevelDateOnCreate', // ФО/ФОП/ЮО
        name: 'Дата встановлення/зміни первинного рівня ризику',
        value: getDateView(alert.riskLevelDateOnCreate, true),
        editable: false,
    },
    {
        key: 'risksCriteria', // ФО/ФОП/ЮО
        name: 'Первинні критерії ризику (за наявності)',
        value: (
            <div>
                {alert.rules
                    ?.filter((model) => model.addedManually === 0 && model.rule.type.code === RULES_TYPE_CRITERION)
                    .map((model) => (
                        <Tooltip title={model.rule.description} key={model.id}>
                            <div className="field__rule-description">
                                {model.rule.code}&nbsp;{model.rule.description}
                            </div>
                        </Tooltip>
                    ))}
            </div>
        ),
        editable: false,
    },
    {
        key: 'riskScore', // ФО/ФОП/ЮО
        name: 'Загальна сума балів по критеріям ризику',
        value: alert.riskSumScore,
        editable: false,
    },
    {
        key: 'suspicionIndicator', // ФО/ФОП/ЮО
        name: 'Первинні індикатори підозрілості (за наявності)',
        value: (
            <div>
                {alert.rules
                    ?.filter((model) => model.addedManually === 0 && model.rule.type.code === RULES_TYPE_INDICATOR)
                    .map((model) => (
                        <Tooltip title={model.rule.description} key={model.id}>
                            <div className="field__rule-description">
                                {model.rule.code}&nbsp;{model.rule.description}
                            </div>
                        </Tooltip>
                    ))}
            </div>
        ),
        editable: false,
    },
];

export const getAdditionalData = (
    alert: ExtendedAlert,
    form?: FormInstance<any>,
    branches?: RecordModel[],
): AlertTabRowData[] => [
    {
        key: 'bankCode',
        name: 'Філія, в якій відкриті рахунки',
        value: alert.customer?.bankCode,
        editable: false,
    },
    {
        key: 'branches',
        name: 'Філія/філії та ТВБВ, в яких обслуговується клієнт',
        value: alert.branches?.map((branch) => branch.id),
        editable: true,
        fieldName: ['branches'],
        type: RowTadaType.select,
        multiply: true,
        options: branches?.map((branch) => ({ value: branch.id, label: branch.description })) || [],
    },
    {
        key: 'Корпоративна електронна скринька Філія',
        name: 'Корпоративна електронна скринька',
        value: '-',
        editable: false,
    },
    {
        key: 'branchName',
        name: 'Відділення, в якому відкриті рахунки',
        value: alert.customer.branchName?.description,
        editable: false,
    },
    {
        key: 'Корпоративна електронна скринька Відділення',
        name: 'Корпоративна електронна скринька',
        value: '-',
        editable: false,
    },
    { key: 'manager', name: 'Менеджер клієнта', value: alert.customer?.manager, editable: false },
    {
        key: 'Корпоративна електронна скринька Менеджер',
        name: 'Корпоративна електронна скринька',
        value: '-',
        editable: false,
    },
    {
        key: 'anotherContactInfo',
        name: 'Інша контактна інформація (за наявності)',
        value: alert.anotherContactInfo,
        editable: true,
        fieldName: ['anotherContactInfo'],
        type: RowTadaType.textArea,
        rows: 2,
    },
    {
        key: 'listOfRequireDocuments',
        name: 'Перелік документів, які потрібно отримати від клієнта з метою спростування/підтвердження підозр та/або з метою вжиття інших ПЗНП, за результатами яких буде прийнято рішення про ризики подальшого обслуговування клієнта в Банку',
        value: alert.listOfRequireDocuments,
        editable: true,
        fieldName: 'listOfRequireDocuments',
        type: RowTadaType.textArea,
        rows: 10,
    },
    {
        key: 'isSendingRequestToCustomer',
        name: 'Чи направлявся клієнту офіційний лист (запит) з метою отримання документів/пояснень?',
        value: alert.isSendingRequestToCustomer,
        editable: true,
        fieldName: 'isSendingRequestToCustomer',
        type: RowTadaType.select,
        options: approveOption,
        required: true,
    },
    {
        key: 'sendingRequestToCustomerDate',
        name: 'Дата направлення запиту клієнту',
        value: getDateView(alert.sendingRequestToCustomerDate),
        editable: true,
        fieldName: 'sendingRequestToCustomerDate',
        type: RowTadaType.date,
        hide: !form?.getFieldValue('isSendingRequestToCustomer'),
        required: form?.getFieldValue('isSendingRequestToCustomer'),
    },
    {
        key: 'numberOfIncomingLetter',
        name: 'Номер вихідного листа',
        value: alert.numberOfIncomingLetter,
        editable: true,
        fieldName: 'numberOfIncomingLetter',
        style: numberOfIncomingLetterStyle,
        hide: !form?.getFieldValue('isSendingRequestToCustomer'),
        required: form?.getFieldValue('isSendingRequestToCustomer'),
    },
    {
        key: 'isRequestReceived',
        name: 'Чи отримав клієнт запит Банку?',
        value: alert.isRequestReceived,
        editable: true,
        fieldName: 'isRequestReceived',
        type: RowTadaType.select,
        options: approveOption,
        required: true,
    },
    {
        key: 'requestReceivedDate',
        name: 'Дата отримання клієнтом запиту Банку',
        value: getDateView(alert.requestReceivedDate),
        editable: true,
        fieldName: 'requestReceivedDate',
        type: RowTadaType.date,
        required: form?.getFieldValue('isRequestReceived') ? true : false,
        hide: !form?.getFieldValue('isRequestReceived'),
    },
    {
        key: 'isDocumentsProvided',
        name: 'Чи надав клієнт на запит Банку документи?',
        value: alert.isDocumentsProvided,
        editable: true,
        fieldName: 'isDocumentsProvided',
        type: RowTadaType.select,
        options: isDocumentsProvidedOptions,
        required: true,
    },
    {
        key: 'providingDocumentsDate',
        name: 'Дата отримання Банком документів від клієнта',
        value: getDateView(alert.providingDocumentsDate),
        editable: true,
        fieldName: 'providingDocumentsDate',
        type: RowTadaType.date,
        required: form?.getFieldValue('isDocumentsProvided'),
        hide: form?.getFieldValue('isDocumentsProvided') !== 1,
    },
    {
        key: 'isCustomerDeclinedProvidingDocuments',
        name: 'Чи була відмова клієнта від надання документів?',
        value: alert.isCustomerDeclinedProvidingDocuments,
        editable: true,
        fieldName: 'isCustomerDeclinedProvidingDocuments',
        type: RowTadaType.select,
        options: approveOption,
        required: true,
    },
    {
        key: 'dateOfDeclineProvidingDocuments',
        name: 'Дата відмови від надання документів',
        value: getDateView(alert.dateOfDeclineProvidingDocuments),
        editable: true,
        fieldName: 'dateOfDeclineProvidingDocuments',
        type: RowTadaType.date,
        hide: !form?.getFieldValue('isCustomerDeclinedProvidingDocuments'),
        required: form?.getFieldValue('isCustomerDeclinedProvidingDocuments'),
    },
    {
        key: 'commentsAboutRequest',
        name: 'Додаткова інформація щодо запиту документів (за наявності)',
        value: alert.commentsAboutRequest,
        editable: true,
        fieldName: 'commentsAboutRequest',
        type: RowTadaType.textArea,
        rows: 2,
    },
];

export const getCommentsData = (
    alert: ExtendedAlert,
    indicators: Option[],
    criteria: Option[],
    minRisksMeasures?: RecordModel[],
    form?: FormInstance<any>,
): AlertTabRowData[] => [
    {
        key: 'commentsAboutProvidingDocuments',
        fieldName: 'commentsAboutProvidingDocuments',
        name: 'Коментарі щодо наданих на запит документів та їх аналізу',
        value: alert.commentsAboutProvidingDocuments,
        editable: true,
        required: true,
        type: RowTadaType.textArea,
        rows: 10,
    },
    {
        key: 'anotherCounterpartiesInfo',
        name: 'Коментарі щодо контрагентів (за наявності)',
        value: alert.anotherCounterpartiesInfo,
        editable: true,
        fieldName: 'anotherCounterpartiesInfo',
        type: RowTadaType.textArea,
        rows: 10,
    },
    {
        key: 'otherMeasuresOfPZNP',
        fieldName: 'otherMeasuresOfPZNP',
        name: 'Інші заходи ПЗНП',
        value: alert.otherMeasuresOfPZNP,
        editable: true,
        required: true,
        type: RowTadaType.textArea,
        rows: 3,
    },
    {
        key: 'negativeInformation',
        fieldName: 'negativeInformation',
        name: 'Наявність негативної інформації у відкритих джерелах та/або отриманої від підрозділу безпеки Банку',
        value: alert.negativeInformation,
        editable: true,
        required: true,
        type: RowTadaType.textArea,
        rows: 3,
    },
    {
        key: 'manuallyCriteria',
        fieldName: 'manuallyCriteria',
        name: 'Критерії ризику, додатково виявлені під час вжиття ПЗНП (за наявності)',
        value: alert.rules
            ?.filter(
                (rule) =>
                    rule.rule.type.code === RULES_TYPE_CRITERION && rule.addedManually === 1 && rule.additional === 0,
            )
            .map((rule) => rule.rule.id),
        editable: true,
        multiply: true,
        type: RowTadaType.select,
        options: criteria,
    },

    {
        key: 'manuallyIndicator',
        fieldName: 'manuallyIndicator',
        name: 'Індикатори підозрілості, додатково виявлені під час вжиття ПЗНП (за наявності)',
        value: alert.rules
            ?.filter(
                (rule) =>
                    rule.rule.type.code === RULES_TYPE_INDICATOR && rule.addedManually === 1 && rule.additional === 0,
            )
            .map((rule) => rule.rule.id),
        editable: true,
        multiply: true,
        type: RowTadaType.select,
        options: indicators,
    },
    {
        key: 'bankImplementationOfOtherNPC',
        fieldName: 'bankImplementationOfOtherNPC',
        name: 'Інформація щодо вжиття банком інших заходів НПК (зокрема актуалізації даних про клієнта, ПЗНП, результатів моніторингу фінансових операцій)',
        value: alert.bankImplementationOfOtherNPC,
        editable: true,
        required: true,
        type: RowTadaType.textArea,
        rows: 2,
        placeholder: 'Введіть значення, яке буде експортовано до електронної анкети клієнта та його ризик-профілю',
    },
    {
        key: 'minRisksMeasures',
        fieldName: 'minRisksMeasures',
        name: 'Заходи, що були вжиті стосовно клієнта з метою мінімізації ризиків ВК/ФТ',
        value: alert.minRisksMeasures?.map((item) => item.id),
        editable: true,
        required: true,
        type: RowTadaType.select,
        options: minRisksMeasures?.map((item) => ({ value: item.id, label: item.description })),
        multiply: true,
        placeholder: 'Оберіть значення, яке буде експортовано до результатів звіту «Моніторинг ділових відносин»',
    },
    {
        key: 'measuresToMinimazeRisks',
        name: 'Інші заходи з метою мінімізації ризиків',
        value: alert.measuresToMinimazeRisks,
        editable: true,
        fieldName: 'measuresToMinimazeRisks',
        required: (() => {
            const otherId = minRisksMeasures?.find((item) => item.description === 'Інше')?.id;
            return form?.getFieldValue('minRisksMeasures')?.some((item: RecordModel['id']) => item === otherId);
        })(),
        type: RowTadaType.textArea,
        rows: 2,
    },
    {
        key: 'additionalComments',
        fieldName: 'additionalComments',
        name: 'Додаткові коментарі',
        value: alert.additionalComments,
        editable: true,
        type: RowTadaType.textArea,
        rows: 2,
    },
];

export const getConclusionData = (
    alert: ExtendedAlert,
    availableManual: Option[] = [],
    availableAdditionalCriteria: Option[] = [],
    solution?: RecordModel[],
    totalRiskScore?: number,
    form?: FormInstance<any>,
    availableManualIndicators?: Option[],
    availableAdditionalIndicators?: Option[],
): AlertTabRowData[] => [
    {
        key: 'conclusionAboutSuspicions',
        fieldName: 'conclusionAboutSuspicions',
        name: 'Висновок щодо наявності/відсутності підозр щодо клієнта та його операцій',
        value: alert.conclusionAboutSuspicions,
        editable: true,
        required: true,
        type: RowTadaType.textArea,
        rows: 5,
    },
    {
        key: 'conclusion',
        fieldName: 'conclusion',
        name: 'Пропозиції',
        value: alert?.conclusion?.map((item) => item.id),
        options: solution?.map((item) => ({ value: item.id, label: item.description })),
        type: RowTadaType.select,
        editable: true,
        required: true,
        multiply: true,
    },
    {
        key: 'other',
        name: 'Інше',
        value: alert.other,
        editable: true,
        fieldName: 'other',
        required: (() => {
            const otherId = solution?.find((item) => item.description === 'Інше')?.id;
            return form?.getFieldValue('conclusion')?.some((item: RecordModel['id']) => item === otherId);
        })(),
        type: RowTadaType.textArea,
        rows: 2,
    },
    {
        key: 'approvedCriteria',
        name: 'Підтверджую доцільність встановлених/виявлених критеріїв ризику',
        value:
            alert.rules
                ?.filter(
                    (model) =>
                        model.rule.type.code === RULES_TYPE_CRITERION && model.approved === 1 && model.additional === 0,
                )
                .map((model) => model.rule.id) || [],
        editable: true,
        fieldName: 'approvedCriteria',
        type: RowTadaType.select,
        options: availableManual,
        multiply: true,
    },
    {
        key: 'declinedCriteria',
        name: 'Не підтверджую доцільність встановлених/виявлених критеріїв ризику',
        value:
            alert.rules
                ?.filter(
                    (model) =>
                        model.rule.type.code === RULES_TYPE_CRITERION && model.approved === 0 && model.additional === 0,
                )
                .map((model) => model.rule.id) || [],
        editable: true,
        fieldName: 'declinedCriteria',
        type: RowTadaType.select,
        options: availableManual,
        multiply: true,
        disabled: true,
    },
    {
        key: 'additionalCriteria',
        name: 'Вважаю за доцільне додатково встановити наступні критеріїв ризику',
        value:
            alert.rules
                ?.filter((model) => model.rule.type.code === RULES_TYPE_CRITERION && model.additional === 1)
                .map((model) => model.rule.id) || [],
        editable: true,
        fieldName: 'additionalCriteria',
        type: RowTadaType.select,
        options: availableAdditionalCriteria,
        multiply: true,
    },
    {
        key: 'totalRiskSumScore',
        name: 'Залишкові бали по підтвердженим критеріям ризику',
        value: totalRiskScore,
        editable: false,
    },
    {
        key: 'approvedIndicators',
        name: 'Підтверджую доцільність встановлених/виявлених індикаторів підозрілості',
        value:
            alert.rules
                ?.filter(
                    (model) =>
                        model.rule.type.code === RULES_TYPE_INDICATOR && model.approved === 1 && model.additional === 0,
                )
                .map((model) => model.rule.id) || [],
        editable: true,
        fieldName: 'approvedIndicators',
        type: RowTadaType.select,
        options: availableManualIndicators,
        multiply: true,
    },
    {
        key: 'declinedIndicators',
        name: 'Не підтверджую доцільність встановлених/виявлених індикаторів підозрілості',
        value:
            alert.rules
                ?.filter(
                    (model) =>
                        model.rule.type.code === RULES_TYPE_INDICATOR && model.approved === 0 && model.additional === 0,
                )
                .map((model) => model.rule.id) || [],
        editable: true,
        fieldName: 'declinedIndicators',
        type: RowTadaType.select,
        options: availableManualIndicators,
        multiply: true,
        disabled: true,
    },
    {
        key: 'additionalIndicators',
        name: 'Вважаю за доцільне додатково встановити наступні індикатори підозрілості',
        value:
            alert.rules
                ?.filter((model) => model.rule.type.code === RULES_TYPE_INDICATOR && model.additional === 1)
                .map((model) => model.rule.id) || [],
        editable: true,
        fieldName: 'additionalIndicators',
        type: RowTadaType.select,
        options: availableAdditionalIndicators,
        multiply: true,
    },
];

export const getApprovalData = (alert: BaseAlert, rolesParams?: UserModel['rolesParams']): AlertTabRowData[] => [
    {
        key: 'controllerComments',
        name: 'Коментарі/зауваження Контролера',
        value: alert.controllerComments,
        editable: false,
    },
    {
        key: 'conclusionDate',
        fieldName: 'conclusionDate',
        name: 'Дата погодження висновку',
        value: getDateView(alert.conclusionDate, !rolesParams?.IS_CONTROLLER),
        editable: rolesParams?.IS_CONTROLLER,
        required: rolesParams?.IS_CONTROLLER ?? false,
        disabledPastPeriod: true,
        type: RowTadaType.date,
    },
    {
        key: 'closeDate',
        name: 'Дата закриття алерту',
        value: alert.closeDate && moment(alert.closeDate).format(DATE_FORMAT),
        editable: false,
    },
];
