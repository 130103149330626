export enum STATUS_CODES {
    new = 'new',
    assigned = 'assigned',
    process = 'process',
    processed = 'processed',
    agreed = 'agreed',
    amendment = 'amendment',
    closed = 'closed',
}

export enum STATUS_CODES_TRANSLATION {
    new = 'Новий',
    assigned = 'Призначено',
    process = 'В роботі',
    processed = 'Опрацьовано',
    agreed = 'Погоджено',
    amendment = 'Доопрацювання',
    closed = 'Закрито',
}

export enum AlertFields {
    id = 'id',
    urgency = 'urgency',
    status = 'status',
    createdDate = 'createdDate',
    sessionCode = 'sessionCode',
    expiredDate = 'expiredDate',
    executionDate = 'executionDate',
    clientName = 'clientName',
    edrpou = 'edrpou',
    clientRegisterNumber = 'clientRegisterNumber',
    mfo = 'mfo',
    nameRa = 'nameRa',
    analyst = 'analyst',
    deadlineProvideDocuments = 'deadlineProvideDocuments',
    conclusion = 'conclusion',
    conclusionDate = 'conclusionDate',
    controller = 'controller',
    customer = 'customer',
    riskSumScore = 'riskSumScore',
    totalRiskSumScore = 'totalRiskSumScore',
    listOfRequireDocuments = 'listOfRequireDocuments',
    sendingRequestToCustomerDate = 'sendingRequestToCustomerDate',
    numberOfIncomingLetter = 'numberOfIncomingLetter',
    providingDocumentsDate = 'providingDocumentsDate',
    commentsAboutProvidingDocuments = 'commentsAboutProvidingDocuments',
    otherMeasuresOfPZNP = 'otherMeasuresOfPZNP',
    negativeInformation = 'negativeInformation',
    conclusionAboutSuspicions = 'conclusionAboutSuspicions',
    other = 'other',
    bankImplementationOfOtherNPC = 'bankImplementationOfOtherNPC',
    measuresToMinimazeRisks = 'measuresToMinimazeRisks',
    additionalComments = 'additionalComments',
    controllerComments = 'controllerComments',
    closeDate = 'closeDate',
    analysisFromDate = 'analysisFromDate',
    analysisToDate = 'analysisToDate',
    session = 'session',
    riskLevelDateOnCreate = 'riskLevelDateOnCreate',
    riskLevelOnCreate = 'riskLevelOnCreate',
    rules = 'rules',
    minRisksMeasures = 'minRisksMeasures',
    isRequestReceived = 'isRequestReceived',
    requestReceivedDate = 'requestReceivedDate',
    isDocumentsProvided = 'isDocumentsProvided',
    documentsProvidedDate = 'documentsProvidedDate',
    commentsAboutRequest = 'commentsAboutRequest',
    anotherCounterpartiesInfo = 'anotherCounterpartiesInfo',
    massRegistrationAddress = 'massRegistrationAddress',
    numberOfRegistredSGD = 'numberOfRegistredSGD',
    kvedQuantity = 'kvedQuantity',
    licensePermitsInfo = 'licensePermitsInfo',
    firstCurrentAccountDate = 'firstCurrentAccountDate',
    anotherContactInfo = 'anotherContactInfo',
    anotherAddressInfo = 'anotherAddressInfo',
    socialStatus = 'socialStatus',
    typeOfEmployment = 'typeOfEmployment',
    work = 'work',
    blacklist = 'blacklist',
    // additional fields for alert
    manuallyCriteria = 'manuallyCriteria',
    approvedCriteria = 'approvedCriteria',
    declinedCriteria = 'declinedCriteria',
    manuallyIndicator = 'manuallyIndicator',
    additionalCriteria = 'additionalCriteria',
    // new fields for alert
    isIndependentActivity = 'isIndependentActivity',
    independentActivityType = 'independentActivityType',
    isClientTrustor = 'isClientTrustor',
    trustorData = 'trustorData',
    isClientTrustedPerson = 'isClientTrustedPerson',
    trustedPersonData = 'trustedPersonData',
    isSendingRequestToCustomer = 'isSendingRequestToCustomer',
    isCustomerDeclinedProvidingDocuments = 'isCustomerDeclinedProvidingDocuments',
    dateOfDeclineProvidingDocuments = 'dateOfDeclineProvidingDocuments',
    isAddressMassRegistration = 'isAddressMassRegistration',
    massRegistrationAddressCount = 'massRegistrationAddressCount',
    isClientBeneficiary = 'isClientBeneficiary',
    clientBeneficiaryCount = 'clientBeneficiaryCount',
    isManyVED = 'isManyVED',
    numberOfEmployees = 'numberOfEmployees',
    isHeadHeadInOtherCompanies = 'isHeadHeadInOtherCompanies',
    headHeadInOtherCompaniesCount = 'headHeadInOtherCompaniesCount',
    isHeadCFOinOtherCompanies = 'isHeadCFOinOtherCompanies',
    headCFOinOtherCompaniesCount = 'headCFOinOtherCompaniesCount',
    isCFOHeadInOtherCompanies = 'isCFOHeadInOtherCompanies',
    CFOHeadInOtherCompaniesCount = 'CFOHeadInOtherCompaniesCount',
    isCFOCFOInOtherCompanies = 'isCFOCFOInOtherCompanies',
    CFOCFOInOtherCompaniesCount = 'CFOCFOInOtherCompaniesCount',
    alertsDataOfLegal = 'alertsDataOfLegal',
    alertsDataOfLegalCfo = 'alertsDataOfLegalCfoData',
    alertsDataOfLegalClient = 'alertsDataOfLegalClientData',
    informationAboutHead = 'informationAboutHead',
    informationAboutCFO = 'informationAboutCFO',
    CFOStake = 'CFOStake',
}
